import React, { useState, useContext, useEffect } from "react";
import { Fade } from "react-reveal";
import Headroom from "react-headroom";
import "./Header.scss";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import StyleContext from "../../contexts/StyleContext";
import {
  greeting,
  workExperiences,
  educationInfo,
  skillsSection,
  openSource,
  blogSection,
  talkSection,
  achievementSection,
  resumeSection
} from "../../portfolio";

function Header() {
  const { isDark } = useContext(StyleContext);
  const [isContentReady, setIsContentReady] = useState(false);
  const viewExperience = workExperiences.display;
  const viewEducation = educationInfo.display;
  const viewOpenSource = openSource.display;
  const viewSkills = skillsSection.display;
  const viewAchievement = achievementSection.display;
  const viewBlog = blogSection.display;
  const viewTalks = talkSection.display;
  const viewResume = resumeSection.display;

  useEffect(() => {
    // Check if fonts are loaded
    document.fonts.ready.then(() => {
      // Additional check for logo visibility
      const checkLogoReady = () => {
        const logo = document.querySelector('.logo');
        if (logo) {
          setIsContentReady(true);
        } else {
          requestAnimationFrame(checkLogoReady);
        }
      };
      checkLogoReady();
    });
  }, []);

  return (
    <Headroom>
      <header className={isDark ? "dark-menu header" : "header"}>
        {isContentReady ? (
          <Fade top duration={400} distance="10px">
            <a href="/" className="logo">
              <span className="grey-color"> &lt;</span>
              <span className={isDark ? "logo-name-dark" : "logo-name"}>
                {greeting.username}
              </span>
              <span className="grey-color">/&gt;</span>
            </a>
          </Fade>
        ) : (
          <a href="/" className="logo" style={{ opacity: 0 }}>
            <span className="grey-color"> &lt;</span>
            <span className={isDark ? "logo-name-dark" : "logo-name"}>
              {greeting.username}
            </span>
            <span className="grey-color">/&gt;</span>
          </a>
        )}

        <input className="menu-btn" type="checkbox" id="menu-btn" />
        <label
          className="menu-icon"
          htmlFor="menu-btn"
          style={{ color: "white" }}
        >
          <span className={isDark ? "navicon navicon-dark" : "navicon"}></span>
        </label>
        <ul className={isDark ? "dark-menu menu" : "menu"}>
          {viewSkills && (
            <li>
              <a href="#skills">Skills</a>
            </li>
          )}
          {viewResume && (
            <li>
              <a
                href="/resume.pdf"
                target="_blank"
                rel="noopener noreferrer"
               >
                Resume
              </a>
            </li>
          )}
          {viewExperience && (
            <li>
              <a href="#experience">Experience</a>
            </li>
          )}
          {viewEducation && (
            <li>
              <a href="#education">Education</a>
            </li>
          )}
          {viewOpenSource && (
            <li>
              <a href="#opensource">Open Source</a>
            </li>
          )}
          {viewAchievement && (
            <li>
              <a href="#achievements">Achievements</a>
            </li>
          )}
          {viewBlog && (
            <li>
              <a href="#blogs">Blogs</a>
            </li>
          )}
          {viewTalks && (
            <li>
              <a href="#talks">Talks</a>
            </li>
          )}
          <li>
            <a href="#contact">Contact</a>
          </li>
          <li>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a>
              <ToggleSwitch />
            </a>
          </li>
        </ul>
      </header>
    </Headroom>
  );
}

export default Header;
