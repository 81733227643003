import React, { useContext, useState, useEffect } from "react";
import "./SplashScreen.css";
import { greeting } from "../../portfolio";
import StyleContext from "../../contexts/StyleContext";
import Fade from "react-reveal/Fade";

export default function SplashScreen() {
  const { isDark } = useContext(StyleContext);
  const [fontLoaded, setFontLoaded] = useState(false);

  useEffect(() => {
    // Disable scrolling on mount
    document.documentElement.style.overflow = "hidden";
    document.body.style.overflow = "hidden";

    // Check if the font is loaded
    const checkFont = async () => {
      try {
        await document.fonts.load('1rem "Agustina"');
        setFontLoaded(true);
      } catch (error) {
        console.error("Error loading font:", error);
        // Fallback: still show the text if font loading fails
        setFontLoaded(true);
      }
    };

    checkFont();

    // Re-enable scrolling when component unmounts
    return () => {
      document.documentElement.style.overflow = "";
      document.body.style.overflow = "";
    };
  }, []);

  return (
    <div className={isDark ? "dark-mode splash-container" : "splash-container"}>
      {fontLoaded && (
        <Fade duration={1400}>
          <div className="splash-title-container">
            <span className="grey-color"> &lt;</span>
            <span className="splash-title">{greeting.username}</span>
            <span className="grey-color">/&gt;</span>
          </div>
        </Fade>
      )}
    </div>
  );
}