import React, { useState, useContext, useEffect } from "react";
import { Fade } from "react-reveal";
import emoji from "react-easy-emoji";
import "./Greeting.scss";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";
import { greeting } from "../../portfolio";
import StyleContext from "../../contexts/StyleContext";

export default function Greeting() {
  const { isDark } = useContext(StyleContext);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isEmojiLoaded, setIsEmojiLoaded] = useState(false);

  useEffect(() => {
    // Preload the emoji
    const emojiElement = document.createElement('span');
    emojiElement.innerHTML = emoji("👋");
    setIsEmojiLoaded(true);
  }, []);

  if (!greeting.displayGreeting) {
    return null;
  }

  return (
    <Fade bottom duration={1000} distance="40px">
      <div className="greet-main" id="greeting">
        <div className="greeting-main">
          <div className="greeting-text-div">
            <div>
              <h1
                className={isDark ? "dark-mode greeting-text" : "greeting-text"}
              >
                {greeting.title}{" "}
                {isEmojiLoaded && (
                  <span className="wave-emoji">{emoji("👋")}</span>
                )}
              </h1>
              <p
                className={
                  isDark
                    ? "dark-mode greeting-text-p"
                    : "greeting-text-p subTitle"
                }
              >
                {greeting.subTitle}
              </p>
              <div id="resume" className="empty-div"></div>
              <SocialMedia />
              <div className="button-greeting-div">
                <Button text="Contact Me" href="#contact" />
                {greeting.resumeLink && (
                  <a
                    href="/resume.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="download-link-button"
                  >
                    <Button text="View Resume" />
                  </a>
                )}
              </div>
            </div>
          </div>
          <div className={isDark ? "greeting-image-div-dark" : "greeting-image-div"}>
            {/* Hidden image to preload and detect load completion */}
            <img
              alt=""
              src={require("../../assets/images/me.jpg")}
              style={{ display: 'none' }}
              onLoad={() => setIsImageLoaded(true)}
            />
            {/* Visible image with fade animation (only after load) */}
            {isImageLoaded && (
              <Fade bottom delay={300} duration={800} distance="20px">
                <img 
                  alt="" 
                  src={require("../../assets/images/me.jpg")}
                />
              </Fade>
            )}
          </div>
        </div>
      </div>
    </Fade>
  );
}