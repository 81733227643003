import React from "react";
import "./WorkExperience.scss";
import ExperienceCard from "../../components/experienceCard/ExperienceCard";
import {workExperiences} from "../../portfolio";

export default function WorkExperience() {
  if (workExperiences.display) {
    return (
      <div className="experience-section" id="experience">
        <h1 className="experience-heading">Experience</h1>
        <div className="experience-card-container">
          {workExperiences.experience.map((experience, index) => (
            <ExperienceCard key={index} experience={experience} />
          ))}
        </div>
      </div>
    );
  }
  return null;
}
